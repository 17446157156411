@tailwind base;
@tailwind components;
@tailwind utilities;
/* font */
@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@400;500;600;700&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,500;1,700&family=Poppins&family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");
/* font */
/* font */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* font */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primary-color: #ff6700;
  --background-color: #faf0f0;
  --bangla-font: "Noto Sans Bengali", sans-serif;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}
a {
  text-decoration: none;
}
ol,
li {
  list-style: none;
}
/* img {
  width: 100%;
  display: block;
  background-size: cover;
} */
body {
  scroll-behavior: smooth;
  font-family: "Hind Siliguri", sans-serif;
  background: var(--background_color);
  overflow-x: hidden;
  scroll-snap-type: proximity;
}
.container {
  width: 1140px;
  margin: 0 auto;
}
.menu:hover .submenu {
  display: block;
  animation-duration: 0.3s;
}
.active {
  color: var(--primary_color);
}
.enroll:hover {
  background: var(--background_color);
  color: var(--primary_color);
  box-shadow: 2px 2px 2px var(--primary_color);
  transition: all 0.3s;
}
.enroll:hover .btns {
  background: var(--primary_color);
  color: white;
  transition: all 0.3s;
}
.enroll:hover .para {
  color: var(--primary_color);
  transition: all 0.3s;
}

.box:hover > h2 {
  color: white;
}

.media:hover .mediaLogo {
  color: white !important;
}

.freelancing {
  background-image: linear-gradient(
    90deg,
    rgba(57, 56, 57, 0.551),
    var(--primary-color)
  );
}
.freelancing-img {
  /* box-shadow: 0px 3px 3px 4px rgba(254, 186, 186, 0.15),
    0px 3px 3px 4px rgba(106, 103, 106, 0.551); */
  border: 1px solid rgb(50, 50, 50);
}
